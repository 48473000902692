import React from 'react';
import formatPhone from '../../utils/format-phone';
import Wrap from '../elements/wrap';
import SocialLinks from '../social-links/social-links';
import SmallContainer from './small-container'
import ContactDetailsCondensed from './contact-details-condensed';

const SmallFooter = ({email, phone, address, facebook, twitter, instagram, pinterest}) => (
	<SmallContainer>
		<ContactDetailsCondensed>
			<a href={`mailto:${email}`}>{email}</a><span>|</span>
			<a href={`tel:${formatPhone(phone)}`}>{phone}</a><span>|</span>
			{address}
		</ContactDetailsCondensed>
		<SocialLinks
			facebook={facebook}
			twitter={twitter}
			pinterest={pinterest}
			instagram={instagram}
			size={24}
		/>
	</SmallContainer>
);

export default SmallFooter;
