import styled from 'styled-components';
import font from '../../theme/font';
import breakpoint from '../../theme/breakpoint';

const ContactDetailsCondensed = styled.div`
	color: #fff;
	font-family: ${font.secondary.family};
	font-weight: normal;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding-bottom: 10px;

	a {
		display: block;
		padding-bottom: 5px;
	}

	span {
		display: none;
	}

	${breakpoint('medium', `
		padding-right: 20px;
		padding-bottom: 0;

		a {
			display: inline;
		}

		span {
			display: inline-block;
			padding: 0 10px;
		}
	`)}
`;

export default ContactDetailsCondensed;
