import styled from 'styled-components';
import color from '../../theme/color';
import breakpoint from '../../theme/breakpoint';

const Image = styled.div`
	background-color: ${color.edward};
	background: url(${props => props.src});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-bottom: 50%;
	position: relative;
	height: 0;

	${props => breakpoint('medium', `
		padding-bottom: ${props.size === 'medium' ? '34%' : '38%'};
	`)}
`;

export default Image;
