import React from 'react';
import styled from 'styled-components';
import {withRouteData} from 'react-static';
import {Link} from '@reach/router';
import {imageType} from '../types';
import color from '../theme/color';
import font from '../theme/font';
import breakpoint from '../theme/breakpoint';
import SecondaryLayout from '../components/layout/secondary';
import Half from '../components/elements/half';
import BannerWithContent from '../components/banner/with-content';
import Logo from '../components/logo/logo';

const Content = styled.div`
	background: ${color.soapstone};
	color: ${color.dune};
	padding: 30px 60px;

	img {
		display: block;
		margin: 0 auto;
		width: 80px;
		height: auto;
	}

	h1, h2, h4 {
		font-family: ${font.gothic.family};
		font-size: 15px;
		font-weight: normal;
		letter-spacing: 1.5px;
		margin: 0;
		text-transform: uppercase;
		text-align: center;
	}

	h1 {
		font-size: 13px;
		margin-bottom: 10px;
	}

	h2 {
		color: ${color.hawaiianTan};
		margin-top: 20px;
	}

	h4 {
		font-family: ${font.primary.family};
		font-size: 13px;
		margin-top: 30px;
		letter-spacing: 1.5px;
	}

	${breakpoint('medium', `
		display: flex;
		align-items: center;
		jusify-content: center;
		padding: 40px 90px;

		img {
			width: 110px;
		}

		h1, h2 {
			font-size: 15px;
		}
	`)}
`;

const Home = props => (
	<SecondaryLayout {...props}>
		<Half>
			<BannerWithContent image={props.left_image}>
				<a href="https://www.cdc.events/weddings">
					<Content>
						<div>
							<Logo width={111} height={106}/>
							<h2>Weddings</h2>
							<h4>Enter</h4>
						</div>
					</Content>
				</a>
			</BannerWithContent>
			<BannerWithContent image={props.right_image}>
				<a href="https://www.cdc.events/events">
					<Content>
						<div>
							<Logo width={111} height={106}/>
							<h2>Events</h2>
							<h4>Enter</h4>
						</div>
					</Content>
				</a>
			</BannerWithContent>
		</Half>
	</SecondaryLayout>
);

Home.propTypes = {
	left_image: imageType,
	right_image: imageType
};

Home.defaultProps = {
	left_image: {url: '/empty.jpg'},
	right_image: {url: '/empty.jpg'}
};

export default withRouteData(Home);
