import React from 'react';
import Logo from '../logo/logo';
import LogoContainer from './logo-container';

const Header = () => (
	<header>
		<LogoContainer type="large">
			<Logo type="large" width={293} height={66}/>
		</LogoContainer>
	</header>
);

export default Header;
