import React from 'react';
import {string, node} from 'prop-types';
import SecondaryHeader from '../header/secondary';
import SmallFooter from '../footer/small';
import Meta from './meta';
import Container from './container';

const SecondaryLayout = ({title, children, ...props}) => (
	<main>
		<Meta title={title}/>
		<SecondaryHeader/>
		<Container>{children}</Container>
		<SmallFooter {...props}/>
	</main>
);

SecondaryLayout.propTypes = {
	title: string,
	children: node
};

SecondaryLayout.defaultProps = {
	title: 'Cambreidge Dining Company',
	children: undefined
};

export default SecondaryLayout;
