import styled from 'styled-components';
import color from '../../theme/color';
import breakpoint from '../../theme/breakpoint';

const SmallContainer = styled.div`
	background: ${color.capePalliser};
	padding: 25px;
	text-align: center;

	ul {
		padding: 0;
		margin-top: 0;
	}

	${breakpoint('medium', `
		display: flex;
		align-items: center;
		justify-content: center;
		height: 73px;
		padding: 0;
	`)}
`;

export default SmallContainer;
