import React from 'react';
import {oneOf, node, number} from 'prop-types';
import {imageType} from '../../types';
import breakpoint from '../../theme/breakpoint';
import Image from './image';

const ImageContainer = Image.extend`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 370px;
	padding-bottom: 25px !important;
	padding: 25px;
	height: auto;

	${props => breakpoint('medium', `
		height: calc(100vh - ${props.heightOffset}px);
	`)}
`;

const BannerWithContent = ({image, size, heightOffset, children}) => (
	<ImageContainer src={image.url} size={size} heightOffset={heightOffset}>
		{children}
	</ImageContainer>
);

BannerWithContent.propTypes = {
	image: imageType.isRequired,
	size: oneOf(['large', 'medium']),
	heightOffset: number,
	children: node
};

BannerWithContent.defaultProps = {
	size: 'medium',
	children: undefined,
	heightOffset: 220
};

export default BannerWithContent;
